import { LoginFlowBase, identificationTypes } from "./login-flow-base";
import * as events from '../events';

class MTNCMFlow extends LoginFlowBase {
    handleIntegratorIframeIdentity() {
        this.integrator.customHeIframeIdentify(
            this.integrator.baseUrl,
            'lp/he/identify',
            {
                return: window.location.href,
                metadata: {
                    check_subscription: 'true',
                },
            }
        );
    }

    onSubscriptionExists(response){
        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
            data: {
                reference: response.reference,
            },
        });
        this.redirectToProduct(response.identity, response.identified);
    }

    onMessage(event, onSuccess, onError) {
        //check the iframe event and then call the correct one
        const res = event.data;
        if (res.status_code == 200) {
            // user is ID'd but not subbed
            this.redirectToProductWithoutAuth()
        } else if (res.status_code == 302) {
            // subscription exists
            if (res.identity && res.identified) {
                this.onSubscriptionExists(res)
            } else {
                // this block shouldn't be hit
                this.redirectToProductWithoutAuth()
            }
        } else {
            this.redirectToProductWithoutAuth()
        }
    }

    redirectToProduct(jwt, identified) {//overridable
        // remove unload message
        window.onbeforeunload = null;

        const redirectUrl = this.getProductRedirectUrl(this.productUrl, 'login', {
            auth: jwt, 
            //should always be 1 but prevents future issues
            identified: identified,
            event_tracking_id: this.metadata.event_tracking_id
        })

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
                withAuth: true
            },
        });

        window.location.assign(redirectUrl);
    }

    redirectToProductWithoutAuth() {//overridable
        // remove unload message
        window.onbeforeunload = null;
        const redirectUrl = this.getProductRedirectUrl(this.productUrl,'login', {
            //add identified 1 despite not being identfied so it makes it through to universal login
            identified: 1,
            event_tracking_id: this.metadata.event_tracking_id
        });

        this.logger.debug(`Flow (${this.name}): redirecting to product without auth`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
                withAuth: false,
            },
        });

        window.location.assign(redirectUrl);
    }

    redirectToLandingPage() {
        // remove unload message
        window.onbeforeunload = null;

        const landingPageUrl = this.metadata['page_attributes-subscribe_landing_page'];

        if (landingPageUrl) {
            this.tracker.track(events.REDIRECT_TO_LANDING_PAGE, {
                landingPage: landingPageUrl
            });

            const landingPageURLOBJ = new URL(landingPageUrl)


            const url = this.getProductRedirectUrl(landingPageURLOBJ.origin, landingPageURLOBJ.pathname, {
                event_tracking_id: this.metadata.event_tracking_id,
            });

            this.logger.debug(`Flow (${this.name}): redirecting to landing page`, url.href);

            window.location.assign(url);
        } else {
            this.logger.debug(`Flow (${this.name}): redirecting to base landing page URL`, window.location.origin);

            window.location.assign(window.location.origin);
        }
    }
}

const MTNCMFlowModule = new MTNCMFlow('MTN CM Login Page', identificationTypes.IFRAME, {})
window.FlowModule = MTNCMFlowModule;
